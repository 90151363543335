import React, { Component } from 'react';
import './homestyle.css';
class MainPageLoad extends Component {
    render() {
        return (
            <div className="pnwyqq _12s3o">
                <div className="_34fNe" data-testid="flex-layout-container">
                    <div data-testid="children-wrapper" className="_3p4LD _2_95H bottomOffsetPadBottom" id="bottom-hud-wrapper">
                    <div className="tyaSQ" loaderheight={120} friction="0.3" loadercustomstyle="[object Object]">
                        <div className="MTgMy" style={{top: '-120px', height: '120px', backgroundColor: 'rgb(240, 240, 245)'}} />
                        <div className="EUFPr v3eLo _2MOkA">
                        <div className="_1T78N">
                            <div className="_1OYOX">
                                <div className="_2v20i" role="button">
                                    <div className="Gtw7V">
                                    <div />
                                    <div className="sc-aXZVg kqvlpO _2He9w">
                                    {JSON.parse(localStorage.getItem("userSetAddress")) !== null && JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
                                        <strong className="home-address text-capitalize mr-1">
                                            {JSON.parse(localStorage.getItem("userSetAddress")).tag}
                                        </strong>
                                    ) : "Other"}
                                    </div>
                                    <div className="_5V4bX">
                                        <svg className="_1Eaan _3OyHE _5V4bX" width={16} height={16} viewBox="0 0 16 16" fill="none" stroke="#36393E">
                                        <path d="M4 6L8 10L12 6" stroke="#36393E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    </div>
                                    <div className="sc-aXZVg cuoJzs _3cIIs">
                                    {localStorage.getItem("userSetAddress") ? (
										<React.Fragment>
											{JSON.parse(localStorage.getItem("userSetAddress")).businessLocation ===
											true ? (
												<span>
													{JSON.parse(localStorage.getItem("userSetAddress")).address}
												</span>
											) : (
												<span>
													{JSON.parse(localStorage.getItem("userSetAddress")).house !==
													null ? (
														<span>
															{JSON.parse(localStorage.getItem("userSetAddress"))
																.house.length > 40 ? 
																`${JSON.parse( localStorage.getItem("userSetAddress")).house}`
																: JSON.parse(localStorage.getItem("userSetAddress")).house
															}
														</span>
													) : (
														<span>
															{JSON.parse(localStorage.getItem("userSetAddress"))
																.address.length > 40
																? `${JSON.parse(
																		localStorage.getItem("userSetAddress")
																	).address}`
																: JSON.parse(localStorage.getItem("userSetAddress"))
																		.address}
														</span>
													)}
												</span>
											)}
										</React.Fragment>
									):(
                                        <span>You home address to delivery</span>
                                    )}
                                    </div>
                                </div>
                                <button className="_148wZ">
                                    <svg className="_1Eaan _116Jv" width={36} height={36} viewBox="0 0 32 32" fill="white">
                                    <circle cx={16} cy={16} r={16} fill="url(#paint0_linear_543_6340)" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.5017 13.32C18.5852 13.32 20.273 11.6766 20.273 9.64944C20.273 7.62338 18.5852 5.97998 16.5017 5.97998C14.4195 5.97998 12.7305 7.62338 12.7305 9.64944C12.7305 11.6766 14.4195 13.32 16.5017 13.32ZM8.65743 20.3424C10.06 17.5014 13.0452 15.5393 16.5001 15.5393C19.9568 15.5393 22.9411 17.5013 24.3431 20.3423C24.9581 21.5885 25.2656 22.2116 24.7097 23.1058C24.1538 24 23.2382 24 21.4069 24H11.5932C9.76204 24 8.84646 24 8.2906 23.1057C7.73475 22.2114 8.04231 21.5884 8.65743 20.3424Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_543_6340" x1={16} y1={0} x2={16} y2={32} gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#36393E" stopOpacity="0.8" />
                                        <stop offset={1} stopColor="#36393E" />
                                        </linearGradient>
                                    </defs>
                                    </svg>
                                </button>
                            </div>
                            <button data-testid="search-container" className="_1Skod hpyD_">
                            <div className="_1AaZg">
                                <div className="sc-aXZVg ftrPfO uSZQU" data-testid="search-placeholder" />
                                <div className="_1wmlH" style={{lineHeight: 0}}>
                                    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" strokecolor="rgba(2, 6, 12, 0.92)" fillcolor="rgba(2, 6, 12, 0.92)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.0998 8.84232C13.0998 11.7418 10.7493 14.0922 7.84989 14.0922C4.95046 14.0922 2.6 11.7418 2.6 8.84232C2.6 5.94289 4.95046 3.59243 7.84989 3.59243C10.7493 3.59243 13.0998 5.94289 13.0998 8.84232ZM12.1431 14.1802C10.9686 15.1261 9.47534 15.6922 7.84989 15.6922C4.0668 15.6922 1 12.6254 1 8.84232C1 5.05923 4.0668 1.99243 7.84989 1.99243C11.633 1.99243 14.6998 5.05923 14.6998 8.84232C14.6998 10.4974 14.1128 12.0153 13.1357 13.1993L18.319 17.9606C18.7226 18.3313 18.7359 18.9637 18.3483 19.3511C17.9634 19.7357 17.3365 19.7254 16.9645 19.3282L12.1431 14.1802Z" fill="rgba(2, 6, 12, 0.92)" fillOpacity="0.92" />
                                    </svg>
                                </div>
                                <div className="_2ZWqx _3MZy1">
                                    <div className="sc-aXZVg bTcOkd _1iqQ0">Search for Store or Item</div>
                                </div>
                            </div>
                            </button>
                        </div>
                        </div>
                        <div className="_3HX_K">
                            <div className="_2mw6_3">
                                <div className="_3WJG_" />
                            </div>
                        </div>
                        <div className="_3HX_KLX">
                            <div className="_2mw63">
                                <div className="_3WJD_" />
                            </div>
                            <div className="_2mw6_3">
                                <div className="_3WJD_" />
                            </div>
                            <div className="_2mw6_3">
                                <div className="_3WJD_" />
                            </div>
                            <div className="_2mw6_3">
                                <div className="_3WJD_" />
                            </div>
                            {/* <div className="_2mw63">
                                <div className="_3WfG_" />
                            </div>
                            <div className="_2mw63">
                                <div className="_3WfG_" />
                            </div>
                            <div className="_2mw63">
                                <div className="_3WfG_" />
                            </div> */}
                        </div>
                        <div className="_3HX_K">
                            <div className="_2dVNI" />
                            <div className="_2dVNI" />
                            <div className="_2dVNI" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainPageLoad;
