import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import Ink from "react-ink";

class MainTopSlider extends Component {
	render() {
		const { slides, size, secondarySlider } = this.props;
		return (
			<React.Fragment>
				<div style={{ margin: "10px" }}>
					{slides.length === 0 ? (
						<ContentLoader
							height={170}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
							<rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
						</ContentLoader>
					) : (
						slides.map((slide, index) => slide && (
							<React.Fragment key={index}>
							<span className="_23GWk _3ee0w">
								{slide.url !== null && slide.url !== "" && slide.url !== undefined ?(
									<NavLink to={slide.url} >
									<img src={slide.data.image} alt="To Banner" className="_3goJ2 _8tpll" loading="lazy" style={{width: '100%', height: '100%', objectFit: 'cover', background: 'rgba(2, 6, 12, 0.15)'}} />
									</NavLink>
								):(
									<img src={slide.data.image} alt="To Banner" className="_3goJ2 _8tpll" loading="lazy" style={{width: '100%', height: '100%', objectFit: 'cover', background: 'rgba(2, 6, 12, 0.15)'}} />
								)}
							</span>
							{/* {console.log(slide.data.image)} */}
							</React.Fragment>
						))
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default MainTopSlider;
